import React from 'react';
import jumpBackIcon from './images/jump-back.svg';
import jumpForwardIcon from './images/jump-forward.svg';
import pauseScreenIcon from './images/pause-screen.svg';
import playScreenImg from './images/play-screen.png';


const ScreenPanel = (props) => {
  const { 
    isVideoPaused,
    onPlayClick, 
    onJumpForward,
    onJumpBack
  } = props;

  const switchPlayImg = () => isVideoPaused ? 'playing' : 'paused';
  
  return (
    <div 
      className='player__screen-panel'
      onClick={ onPlayClick }
    >

      {/* кнопка Jump Back */}
      <div 
        className='player__screen-jump-back player__screen-item'
        onClick={ onJumpBack }
      >
        <img src={ jumpBackIcon } alt='' />
      </div>


      {/* кнопки Play / Pause */}
      <div
        className='player__screen-play player__screen-item' 
        onClick={ onPlayClick }
      >
        <img className={ 'player__screen-play-img ' + switchPlayImg() } src={ playScreenImg } alt='' />
        <img className={ 'player__screen-pause-img ' + switchPlayImg() } src={ pauseScreenIcon } alt='' />
      </div>


      {/* кнопка Jump Forward */}
      <div 
        className='player__screen-jump-forward player__screen-item' 
        onClick={ onJumpForward }
      >
        <img src={ jumpForwardIcon } alt='' />
      </div>

    </div>
  );
};

export default ScreenPanel;
