import CardsService from './cardsService';
import cardsData from './cardsData';

/*
В приложении будут доступны карточки по перечисленным темам.
Если карта есть, а тема здесь не указана - карта не отображается.

Поэтому алгоритм следующий:
1. добавил новые карточки в cardsData
2. добавил сюда их тему

.getThemeCardsCount дозаписывает кол-во карт по каждой теме.
Эти значения используются, где надо указать кол-во видео (или опросов) по выбранной теме

isActive      - отображается ли тема в списке активных
themeSubtitle - подзаголовок (используется на странице с настройками)
mediaCount    - кол-во карточек (видео, опросов) по данной теме
*/


const cardsService = new CardsService();

const themes = [
  
  { themeName: 'culture',  isActual: true,  isActive: true,  isPermanent: true,  mediaCount: 0, themeOrder: 2, themeSubtitle: 'Power to the people' },
  { themeName: 'people',   isActual: false, isActive: true,  isPermanent: true,  mediaCount: 0, themeOrder: 3, themeSubtitle: 'Yes, we can' },
  { themeName: 'legal',    isActual: false, isActive: false, isPermanent: false, mediaCount: 0, themeOrder: 4, themeSubtitle: 'Your Partner in crime' },
  { themeName: 'business', isActual: false, isActive: false, isPermanent: false, mediaCount: 0, themeOrder: 5, themeSubtitle: 'New York, Rio, Eisenach' },
  
  ].map((theme) => 
    ({...theme, mediaCount: cardsService.getThemeCardsCount(theme.themeName, cardsData)})
);


export default themes;
