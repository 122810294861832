import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './Briefing.css';
import readMoreArrow from './images/read-more-arrow.svg'
import transparentBg from './images/transparent-bg.svg';
import Player from '../Player';
import textData from '../../redux/textData';

import QuizPreview from '../QuizPreview';
import QuizModal from '../QuizModal';

// import vid from '../../assets/video/01_Netflix_People_ohne_Logo.mp4';
import fakeCover from './images/fake-video-cover.png';

const Briefing = (props) => {
  const { 
    activeCard = { type: 'null', name: 'Name' },
    isQuizVisible
  } = props;

  let content;

  switch(activeCard.type) {
    case ('video'):
      content = <Player />;
      break;
    
    case ('quiz'):
      content = <QuizPreview activeCard={ activeCard }/>;
      break;
  }

  return (
    <div className="briefing">

      <TextWrapper activeCardName={ activeCard.name }/>

      <div className="briefing__video-wrapper">
        <img className='briefing__video-wrapper-bg' src={transparentBg} alt='' />
        { content }
      </div>

      { isQuizVisible ? <QuizModal /> : undefined }

    </div>
  );
};



function TextWrapper(props) {
  const { activeCardName = 'culture1' } = props;

  function createMarkup() {
    const html = textData[activeCardName]?.body;
    if (!html) {
      return 'Text Data';
    }

    return {__html: html};
  }
  
  const onDeployBtnClick = (ev) => {
    const target = ev.target.closest('.js-briefing__read-more-btn');
    const textContainer = target.closest('.briefing__text-wrapper').querySelector('.js-briefing__text');
    textContainer.classList.toggle('deploy');
    
    if (textContainer.classList.contains('deploy')) {
      target.querySelector('img').style.transform = 'rotate(180deg)';
      target.querySelector('span').innerText = 'weniger lesen';
      return;
    }

    target.querySelector('img').style.transform = '';
    target.querySelector('span').innerText = 'mehr lesen';
  };

  return (
    <div className="briefing__text-wrapper">
      <h2 className="briefing__text-title">{ textData[activeCardName]?.title }</h2>
      <div className="briefing__text js-briefing__text" dangerouslySetInnerHTML={createMarkup()}>
      </div>
      <a onClick={ onDeployBtnClick } className="briefing__read-more-btn js-briefing__read-more-btn">
        <span>mehr lesen</span> <img src={ readMoreArrow } alt="" />
      </a> 
    </div>
  );
}


const mapProps = (state) => {
  return {
    activeCard: state.cardsData.find((card) => card.name === state.activeCard),
    isQuizVisible: state.isQuizVisible
  };
};


export default connect(mapProps)(Briefing);