import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ScreenPanel from './ScreenPanel';
import ControlsPanel from './ControlsPanel';
import PlayerService from './playerService';
// import testSrc from '../../assets/video/01_Netflix_People_ohne_Logo.mp4';
import closeCross from './images/close-cross.svg';
import posterTest from './images/empty-src.jpg';
import './Player.css';


// const cardsService = new CardsService();
const playerService = new PlayerService();


const Player = (props) => {
  const { 
    src = '',               // источник видео
    poster = posterTest,    // обложка
    dispatchOpenNextCards,
    dispatchUpdateMaxTime,

    onCloseAdditional,
    dispatchResetNew,
    dispatchResetNewFromSomeCards,
    resetNewStatusCards
  } = props;

  const [ isVideoPaused, setVideoPaused ] = useState(true);
  const [ time, setTime ] = useState(0);
  const [ duration, setDuration ] = useState(0);
  const [ timer, setTimer ] = useState(null);


  // сменился источник видео - сбросить время
  useEffect(() => {
    setTime(0);
    setVideoPaused(true);
    }, [src]);



  // воспроизведение
  const onPlayClick = (ev) => {
    if (!src) return;
    ev.preventDefault();
    ev.stopPropagation();
    const video = playerService.getVideoElementFromTarget(ev);
    video.paused ? video.play() : video.pause();
    setVideoPaused(video.paused);
    
    const player = playerService.getPlayerFromTarget(ev);
    player.requestFullscreen();

    if (props.name === 'culture7') {
      dispatchResetNewFromSomeCards(resetNewStatusCards)
    }
    if (props.name === 'people3') {
      dispatchResetNewFromSomeCards(resetNewStatusCards)
    }
    if (props.name === 'people6') {
      dispatchResetNewFromSomeCards(resetNewStatusCards)
    }
  };

  const onJumpForward = (ev) => {
    ev.stopPropagation();
    playerService.getVideoElementFromTarget(ev).currentTime += 10;
  };

  const onJumpBack = (ev) => {
    ev.stopPropagation();
    playerService.getVideoElementFromTarget(ev).currentTime -= 10;
  };

  const onCloseClick = (ev) => {
    document.exitFullscreen();

    const player = playerService.getPlayerFromTarget(ev);
    player.classList.remove('full-screen');
    const video = playerService.getVideoElementFromTarget(ev);
    video.pause();
    setVideoPaused(true);

    if (onCloseAdditional) {
      onCloseAdditional();
    }
    ev.stopPropagation();
  }

  // Отображение элементов управления
  const onMouseMove = (ev) => {
    clearTimeout(timer);
    const player = playerService.getPlayerFromTarget(ev);
    if (!player) return;
    player.classList.add('mouse-moving');
    player.classList.remove('mouse-out');

    const target = ev.target;
    if (target.closest('.player__screen-item') || target.closest('.player__controls-panel')) {
      ev.stopPropagation();
      clearTimeout(timer);
      setTimer(null);
      return;
    };
    setTimer(setTimeout(() => { player.classList.remove('mouse-moving') }, 1500));
  };

  const onMouseLeave = (ev) => {
    const player = playerService.getPlayerFromTarget(ev);
    player.classList.remove('mouse-moving');

    if (isVideoPaused) {
      src ? player.classList.add('mouse-out') : player.classList.remove(('mouse-out'));
    }
  };

  const onEnded = (ev) => {
    playerService.getPlayerFromTarget(ev).classList.add('mouse-out');
    setVideoPaused(true);
  }

  const onEmptied = (ev) => {
    const player = playerService.getPlayerFromTarget(ev);
    player.classList.add('mouse-out');
  }


  // Время, прогресс и данные
  const onTimeUpdate = (ev) => {
    const video = playerService.getVideoElementFromTarget(ev);
    if (!src) {
      return;
    }
    setTime(Math.floor(video.currentTime));

    const viewedPercent = Math.floor(100 / video.duration * video.currentTime);

    if (dispatchUpdateMaxTime) {
      const payload = { 
        name:          props.name,
        maxViewedTime: props.maxViewedTime,
        currentTime:   video.currentTime,
        duration:      video.duration,
        nextMedia:     props.nextMedia
      };
      dispatchUpdateMaxTime(payload);
    }

    if (dispatchOpenNextCards && viewedPercent > 10) {
      const payload = { 
        currentTime:   video.currentTime,
        duration:      video.duration,
        nextMedia:     props.nextMedia,
        viewedPercent
      };
      dispatchOpenNextCards(payload);
    }

    if (props.isNew && props.viewedPercent > 10) {
      dispatchResetNew(props.name);
    } 

  }

  const onLoadedMetadata = (ev) => {
    setDuration(playerService.getVideoElementFromTarget(ev).duration);
  };

  const onProgressChange = (ev) => {
    playerService.getVideoElement().currentTime = ev.target.value;
  }



  // свойства для компонентов
  const playerProps = {
    onMouseMove,
    onMouseLeave,
    
  };
  const videoProps = { 
    src,
    poster,
    onClick: onPlayClick,
    onTimeUpdate,
    onLoadedMetadata,
    onEnded,
    onEmptied,
  };
  const screenPanelProps = {
    isVideoPaused,
    onPlayClick,
    onJumpForward,
    onJumpBack
  };
  const controlsPanelProps = {
    onPlayClick,
    time,
    playerService,
    duration,
    onProgressChange,
    isVideoPaused
  };
  const closeCrossProps = {
    src:     closeCross,
    onClick: onCloseClick
  };

  return (
    <div className='player mouse-out'
      {...playerProps} 
    >

      <img className='player__close-cross' {...closeCrossProps} />

      { src ? <video className='player__video' {...videoProps}></video> : undefined }

      {src ? <ScreenPanel { ...screenPanelProps }/> : undefined }

      <div className='player__controls-wrapper'>
        {src ? <ControlsPanel {...controlsPanelProps}/> : undefined }
      </div>
    </div>

  );
};


// export default connect(mapStateToProps, mapDispatchToProps)(Player);

document.addEventListener('fullscreenchange', (ev) => {
  const element = document.fullscreenElement;
  if (!element && document.querySelector('.player')) {
    document.querySelector('.player').classList.remove('full-screen');
  }
  if (element && element.classList.contains('player')) {
    element.classList.add('full-screen');
  }
});


const mapState = (state) => {
  const activeCard = state.cardsData.find((card) => card.name === state.activeCard);
  return {
    name:           activeCard.name,
    src:            activeCard.src,
    maxViewedTime:  activeCard.maxViewedTime,
    nextMedia:      activeCard.nextMedia,
    isNew:          activeCard.isNew,
    viewedPercent:  activeCard.viewedPercent,
    resetNewStatusCards: activeCard.resetNewStatusCards,
    poster:         activeCard.poster,
  }
};



const mapDispatch = (dispatch) => ({

  dispatchOpenNextCards: (payload) => {
    // const viewedPercent = Math.floor(100 / payload.duration * payload.currentTime);
    if (payload.viewedPercent < 10) return;
    dispatch({ type: 'unlock_cards', payload: payload.nextMedia });
  },
  
  dispatchUpdateMaxTime: (payload) => {
    const { 
      name, 
      maxViewedTime, 
      currentTime, 
      duration
    } = payload;

    if (maxViewedTime >= currentTime) return;
    const viewedPercent = Math.floor(100 / duration * currentTime);
    dispatch({
      type:    'update_card_max_time', 
      payload: { name, maxViewedTime: currentTime, viewedPercent }
    });
  },

  dispatchResetNew: (payload) => dispatch({ type: 'reset_new', payload }),

  dispatchResetNewFromSomeCards: (payload) => dispatch({type: 'reset_new_from_some_cards', payload })

});

export default connect(mapState, mapDispatch)(Player);