import Player from '../../components/Player';
import { connect } from 'react-redux';
import './PlayerPage.css';


import TopicCard from '../../components/TopicCard';
import { useEffect, useState } from 'react';

const PlayerPage = (props) => {

  const {
    activeCard,
    cardData2,
    cardData3,
    cardData4,
    onTimeUpdateAdditional
  } = props;

  
  // const header = document.querySelector('.header');
  // if (header) {
  //   header.style.display='none';
  // }


  return (
    <div className='player-page'>
      <div className='player-page__player-wrapper'>
        <Player />
      </div>
      
      <div className='player-page__buttons'>
        <TopicCard {...cardData2} />
        <TopicCard {...cardData3} />
        <TopicCard {...cardData4} />
        <TopicCard />
      </div>

    </div>
  );

};



const mapState = (state) => {

  let activeCard = state.cardsData.find((card) => card.name === state.activeCard); 
  if (!activeCard) activeCard = {};

  return {
    activeCard,
    cardData2:  state.cardsData.find((card) => card.name === 'culture1'),
    cardData3:  state.cardsData.find((card) => card.name === 'culture2'),
    cardData4:  state.cardsData.find((card) => card.name === 'culture3'),
  }
};

// const mapDispatch = (dispatch) => ({
  
//   onTimeUpdateAdditional: (payload) => {
//     const { 
//       name, 
//       maxViewedTime, 
//       currentTime, 
//       duration 
//     } = payload;
    
//     if (maxViewedTime >= currentTime) return;
//     const viewedPercent = Math.floor(100 / duration * currentTime);
//     dispatch({
//       type:    'update_card_max_time', 
//       payload: { name, maxViewedTime: currentTime, viewedPercent }
//     });
//   }
// });


export default connect(mapState)(PlayerPage);