import React, { useState } from 'react';
import { connect } from 'react-redux';
import SettingsForm from './SettingsForm';
import FormService from '../../services/formService';
import './SettingsForm.css';

const formService = new FormService();

function SettingsFormGroupItem(props) {
  const {
    name,
    value, 
    label = 'noLabel', 
    type = 'text',
    onChangeFunction = () => {}
  } = props;

  return (
    <div className="settings-form__group-item">
      <label>
        { label }

        <input 
          type={ type } 
          value={ value }
          name={ name }
          onChange={ onChangeFunction }
        />

      </label>
    </div>
  );
}



let FormPersonal = (props) => {
  const { profileData, applyNewSettings } = props;

  const [formValues, setFormValues] = useState(profileData);
  const onChangeInput = (ev) => {
    setFormValues({...formValues, [ev.target.name]: ev.target.value });
  };

  // достать данные из инпутов в обёртке FormPersonal
  const getPersonalInputsData = (ev) => {
    const formElements = [...ev.target.form.elements];
    const data = formElements
      .reduce((acc, el) => {
        if (el.tagName !== "INPUT") return acc;
        const settingName = el.name;
        const settingValue = el.value;
        acc[settingName] = settingValue;
        return acc;
      }, {});
    return data;
  };

  const onSubmitBtn = (ev) => {
    const data = getPersonalInputsData(ev);
    applyNewSettings(data);
  };


  return (
    <SettingsForm onSubmitBtn={ onSubmitBtn }>
      <div className='settings-form__group-inline'>
        
        <SettingsFormGroupItem
          label={ 'Name' }
          name={ 'name' }
          value={ formValues.name }
          onChangeFunction={ onChangeInput }
        />
        <SettingsFormGroupItem
          label={ 'Abteilung'}
          name={ 'abteilung' }
          value={ formValues.abteilung }
          onChangeFunction={ onChangeInput }
        />

      </div>

      <div className='settings-form__group-inline'>

        <SettingsFormGroupItem
          label={ 'Ort'}
          name={ 'ort' }
          value={ formValues.ort }
          onChangeFunction={ onChangeInput }
        />
        <SettingsFormGroupItem
          label={ 'Unternehmen'}
          name={ 'unternehmen' }
          value={ formValues.unternehmen }
          onChangeFunction={ onChangeInput }
        />
        
      </div>

      <div className='settings-form__group-inline'>
        <SettingsFormGroupItem
          label={ 'Geburtsdatum'}
          name={ 'geburtsdatum' }
          value={ formValues.geburtsdatum }
          onChangeFunction={ onChangeInput }
        />
      </div>
    </SettingsForm>
  );
};



let FormPassword = (props) => {
  const { oldPassword,  applyNewSettings} = props;

  const initialPasswordState = {
    oldPasswordRepeat: '',
    newPassword:       '',
    newPasswordRepeat: ''
  };


  const [formValues, setFormValues] = useState(initialPasswordState);
  const onChangeInput = (ev) => {
    setFormValues({...formValues, [ev.target.name]: ev.target.value });
  };


  const onSubmitBtn = (ev) => {
    const form = ev.target.form;
    const newPassword = "" + form.elements['newPassword'].value;
    const isOldPasswordsMatch = "" + form.elements['oldPasswordRepeat'].value === "" + oldPassword;
    const isNewPasswordsMatch = "" + form.elements['newPasswordRepeat'].value === "" + newPassword;

    if (!(isOldPasswordsMatch && isNewPasswordsMatch)) {
      form.querySelectorAll('[type="password"]').forEach((input) => {
        formService.onErrorInput(input);
      });
      setFormValues({ ...initialPasswordState });
      return;
    }
    
    applyNewSettings({ password: newPassword });
    form.classList.add('success');
    setTimeout(() => form.classList.remove('success'), 500);
    setFormValues({ ...initialPasswordState });
  };

  return (
    <SettingsForm 
      addClass={'settings-form__groups-wrapper--column'} 
      onSubmitBtn={ onSubmitBtn }
    >
      <SettingsFormGroupItem
        label={ 'Aktuelles Passwort'}
        name={ 'oldPasswordRepeat' }
        value={ formValues.oldPasswordRepeat }
        type={ 'password' }
        onChangeFunction={ onChangeInput }
      />
      <SettingsFormGroupItem
        label={ 'Neues Passwort'}
        name={ 'newPassword' }
        value={ formValues.newPassword }
        type={ 'password' }
        onChangeFunction={ onChangeInput }
      />
      <SettingsFormGroupItem
        label={ 'Neues Passwort wiederholen'}
        name={ 'newPasswordRepeat' }
        value={ formValues.newPasswordRepeat }
        type={ 'password' }
        onChangeFunction={ onChangeInput }
      />
    </SettingsForm>
  );
};


let FormEmail = (props) => {
  const { profileData } = props;
  // const profileData = {
    // oldEmail: 'mail',
    // newEmail: 'mail2'
  // }

  // изменения в инпутах формы хранятся здесь.
  const [formValues, setFormValues] = useState(profileData);
  const onChangeInput = (ev) => {
    setFormValues({...formValues, [ev.target.name]: ev.target.value });
  };

  return (
    <SettingsForm addClass={'settings-form__groups-wrapper--column'}>
      <SettingsFormGroupItem
        label={ 'Aktuelle E-Mail'}
        name={ 'oldEmail' }
        value={ formValues.mail }
        type={ 'text' }
        onChange={ onChangeInput }
      />
      <SettingsFormGroupItem
        label={ 'Zusätzliche E-Mail'}
        name={ 'newEmail' }
        // value={ '' }
        type={ 'text' }
        // onChange={ onChangeInput }
      />
      {/* <SettingsFormGroupItem
        label={ 'Neue E-Mail wiederholem'}
        name={ 'newEmailRepeat' }
        value={ 'Timo.W01@gmail.com' }
        type={ 'text' }
      /> */}
    </SettingsForm>
  );
};


const profileDataProps = (store) => ({ profileData: store.settings });
const passwordDataProps = (store) => ({ oldPassword: store.settings.password });

const profileActions = (dispatch) => (
  { applyNewSettings: (payload) => dispatch({ type: 'set_profile_data', payload })}
);


FormPersonal = connect(profileDataProps, profileActions)(FormPersonal);
FormPassword = connect(passwordDataProps, profileActions)(FormPassword);
FormEmail    = connect(profileDataProps, profileActions)(FormEmail);


export {
  FormPersonal,
  FormPassword,
  FormEmail
};