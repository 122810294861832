import React from 'react';
import { connect } from 'react-redux';
import smiles from './images/smiles.svg';
import btnBlack from './images/btn-black.svg';
import btnGold from './images/btn-gold.svg';

import Btn from '../Btn';

import './QuizModal.css';


const QuizModal = (props) => {
  const {
    activeCard,
    dispatchHideQuiz,
    dispatchOpenNextCards,
    dispatchMaxPercent
  } = props;

  const onClickFunction = () => {
    dispatchHideQuiz();
    dispatchMaxPercent({name: activeCard.name, percent: 100 });
    if (activeCard.nextMedia) {
      dispatchOpenNextCards(activeCard.nextMedia);
    }

    if (activeCard.name === 'culture11') {
      dispatchMaxPercent({name: 'culture10', percent: 65 });
      dispatchMaxPercent({name: 'culture9', percent: 30 });
      dispatchMaxPercent({name: 'culture8', percent: 80 });
    }
  }

  return (
    <div className='quiz-modal'>

      <div className='quiz-modal__content'>
        <div className='quiz-modal__content-result'>
          Du hast 2 von 3 Fragen richtig beantwortet
        </div>
        <div className='quiz-modal__content-phrase'>
          Das war ok.
        </div>
        <img className='quiz-modal__content-smiles' src={smiles} alt='' />

        {/* кнопки */}
        <div className='quiz-modal__buttons-wrapper'>
        <div className='quiz-modal__btn quiz-modal__btn--black'>
          Wiederholen <img src={btnBlack} alt=''/>
        </div>
        <div className='quiz-modal__btn quiz-modal__btn--gold' onClick={ onClickFunction }>
          <span>Zur Übersicht</span> <img src={btnGold} alt=''/>
        </div>
      </div>

      </div>

      

    </div>
  )
};

const mapState = (state) => ({ 
  activeCard: state.cardsData.find((card) => card.name === state.activeCard)
});

const mapDispatch = (dispatch) => ({
  dispatchHideQuiz: () => dispatch({ type: 'hide_quiz' }),

  dispatchOpenNextCards: (payload) => {
    dispatch({ type: 'unlock_cards', payload });
  },

  dispatchMaxPercent: (payload) => dispatch({ type: 'update_card_percent', payload })
});

export default connect(mapState, mapDispatch)(QuizModal);