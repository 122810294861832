import React from 'react';
import { connect } from 'react-redux';
import { Routes, Route, Link } from 'react-router-dom';
import './Header.css';

import userDefaultImg from './images/login-icon.svg';
import loggedUserImg from './images/logged-user-img.png';
import logoImg from './images/header-logo.png';
import settingsIcon from './images/menu-profile.svg';
import logoutIcon from './images/menu-logout.svg';
import newsIcon from './images/menu-news-black.svg';


const Header = (props) => {
  const { isLogged, name, logOut } = props;

  const userPhoto = isLogged ? loggedUserImg : userDefaultImg;
  const userName = isLogged ? name : 'Login'

  const onHeaderMenuClick = (ev) => {
    if (!isLogged) {
      ev.target.closest('.header__user-wrapper').classList.remove('active');  
      return
    };
    ev.target.closest('.header__user-wrapper').classList.toggle('active');
  }

  if (!isLogged) {  
    return <div></div>
  };

  return (
    <header className="header">
      <div className="header__inner">
        <Link to='/'>
          <div className="header__logo-wrapper">
            <img src={ logoImg } alt="" />
            <span>Digital Corporate Masterclass</span>
          </div>
        </Link>

        <div 
          className="header__user-wrapper" 
          onClick={ onHeaderMenuClick }
        >
          <span className="header__user-text"> { userName } </span>
          <img className="header__user-avatar" src={ userPhoto } alt="" />
          <HeaderMenu logOut={ logOut } />
        </div>
      </div>
    </header>
  );
};


function HeaderMenu({ logOut }) {

  const onLogOutClick = (ev) => {
    // document.querySelector('.header__user-wrapper').classList.remove('active')
    ev.target.closest('.header__user-wrapper').classList.remove('active');
    logOut();
    ev.stopPropagation();
  }

  const onNewsClick = (ev) => {
    localStorage.clear();
    window.location.reload();
    ev.stopPropagation();
  }

  return (
    <div className='header-menu'>
      <div className='header-menu__item'>
        <Link to={'settings/profile'}>
          <img src={ settingsIcon }/> Mein Profil
        </Link>
      </div>

      <div className='header-menu__item' onClick={ onNewsClick }>
        <Link to={'/'}>
          <img src={ newsIcon }/> Nachrichten
        </Link>
      </div>

      <div className='header-menu__item' onClick={ onLogOutClick }>
        <img src={ logoutIcon }/>Log out
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  name: state.settings.name,
  isLogged: state.isLogged
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch( { type: 'set_is_logged', payload: false })
});


export default connect(mapStateToProps, mapDispatchToProps)(Header);