import React from 'react';
import { connect } from 'react-redux';
import TopicSlider from '../TopicSlider';
import TopicCard from '../TopicCard';
import './TopicThemeSection.css';


const TopicThemeSection = (props) => {
  const { theme, cardsData } = props;
  if (!theme || !cardsData) return;

  const relatedСardsData = cardsData.filter((data) => data.theme === theme);
  const cards = relatedСardsData.map((data) => <TopicCard key={ data.name } {...data}/>);
  const cardsCount = cards.length;

  return (
    <div 
      className='topic-theme-section'
      data-theme-name={ theme }
    >

      <div className='topic-theme-section__info'>
        <h2 className='topic-theme-section__title'>{ theme }</h2>
        <span className='topic-theme-section__video-count'>{ cardsCount + ' Videos' }</span>
      </div>
      
      <TopicSlider cards={ cards }/>
    </div>

  );
};


const mapStateToProps = (state) => ({ cardsData: state.cardsData });
export default connect(mapStateToProps)(TopicThemeSection);