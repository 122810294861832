import React from 'react';
import InteresForm from '../InteresForm';
import InteresSelectedPreview from '../InteresSelectedPreview';
import './SettingsInteres.css';

const SettingsInteres = (props) => {

  return (
    <div className='settings-interes'>
      <InteresForm />
      <div className='settings-interes__gold-line'></div>

      <InteresSelectedPreview />
    </div>
    

    
  )
};


export default SettingsInteres;