import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, Outlet, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthPage from './pages/AuthPage/AuthPage';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

import SettingsPage from './pages/SettingsPage';
import SettingsProfile from './components/SettingsProfile';
import SettingsInteres from './components/SettingsInteres';
import Player from './components/Player';

// import Player from './components/Player';
import PlayerPage from './pages/PlayerOnly';

import Header from './components/Header/Header';
import DashboardPage from './pages/DashboardPage';
import './App.css';
import './assets/fonts/fonts.css';


function App(props) {
  const { isLogged, isWelcomeVideoShown, dispatchWellcomeVideoShown } = props;

  let content = (
    <React.Fragment>
      <Header />
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={ <DashboardPage /> } />

          <Route path="settings/*" element={ <SettingsPage> <Outlet /> </SettingsPage> }>
            <Route path="profile" element={ <SettingsProfile /> } />
            <Route path="interes" element={ <SettingsInteres /> } />
          </Route>

          <Route path="/video" element={ <Player /> } />
          <Route path="/playerPage" element={ <PlayerPage /> } />

        </Routes>
      </ErrorBoundary >
    </React.Fragment>
  );

  if (!isLogged) {
    content = <AuthPage />
  }

  if (isLogged && !isWelcomeVideoShown) {
    return (
      // <div className='wellcome-video' onClick={(ev) => ev.target.querySelector('.player').request}>
      <div className='wellcome-video'>
        <Player onCloseAdditional={ dispatchWellcomeVideoShown } />
      </div>
    )
  }

  return (
    <div className='app'>
      {/* <Header /> */}
      { content }
    </div>
  );
}


document.addEventListener('click', (ev) => {
  if (!ev.target.closest('.header__user-wrapper')) {
    const menu = document.querySelector('.header__user-wrapper');
    if (menu) {
      menu.classList.remove('active');
    }
  }
})

const mapStateToProps = (state) => ({ 
  isLogged:            state.isLogged,
  isWelcomeVideoShown: state.isWelcomeVideoShown
});

const mapDispatch = (dispatch) => ({
  dispatchWellcomeVideoShown: () => dispatch({type: 'set_wellcome_video_shown'})
});


export default connect(mapStateToProps, mapDispatch)(App);
