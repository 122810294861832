import React from 'react';
import AuthFrom from '../../components/AuthForm';
import './AuthPage.css';
import { animatedLogo } from '../../assets/video/videoData';

import dcmLogo from './images/dcm-logo-big.png';


const AuthPage = () => {

  return (
    <section className="intro">
      <div className="section-inner intro__inner">

        <div className="intro__logo-wrapper">
          <video className="intro__animated-logo" src={animatedLogo} muted autoPlay></video>
        </div>

        <div className="intro__auth-wrapper">
          <AuthFrom />
        </div>

      </div>
    </section>
  );
};


export default AuthPage;