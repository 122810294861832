import React from 'react';
import Btn from '../Btn';
import './SettingsForm.css';


const SettingsForm = (props) => {
  const { 
    children, 
    addClass = '',
    onSubmitBtn = () => console.log('no submit foo')
  } = props;

  return (
    <form 
      className={'settings-form ' + addClass} 
      action="/">
        { children }

        <Btn
          text={ 'speichern' }
          addClass={ 'settings-form__btn' }
          onClickFunction={ onSubmitBtn }
        />
    </form>
  );
};


export default SettingsForm;
