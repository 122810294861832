import React from 'react';
import { Link } from 'react-router-dom';
import './SidePanel.css';

import overviewIcon    from './images/icon-overview-black.svg';
import nachrichtenIcon from './images/icon-nachrichten-black.svg';
import interessenIcon  from './images/icon-interessen-black.svg';
import einstellungenIcon from './images/icon-einstellungen-black.svg';
import hilfeIcon from './images/icon-hilfe-black.svg';


const highlightActiveLink = (ev) => {
  const target = ev.target.closest('.side-panel__data-item-title');
  if (!target) return;
  target
    .closest('.side-panel__data-wrapper')
    .querySelectorAll('.side-panel__data-item-title')
    .forEach((link) => {
      if (link === target) {
        link.classList.add('active');
        return;
      }
      link.classList.remove('active');
    }
  );
};


const SidePanel = () => {

  return (
    <div 
      className="side-panel" 
      onClick={ highlightActiveLink }
    >

      <h2 className="side-panel__title">Tim Weise</h2>

      <div className="side-panel__data-wrapper">

        {/* Overview */}
        <div className="side-panel__data-item">
          <div className="side-panel__data-item-title">
            <img src={ overviewIcon } alt="" />
            <a>Overview</a>
          </div>        
        </div>

        {/* Nachrichten */}
        <div className="side-panel__data-item">
          <div className="side-panel__data-item-title">
            <img src={ nachrichtenIcon } alt="" />
            <a>Nachrichten</a>
          </div>
          <ul className="side-panel__data-item-list">
            <li>Eingang</li>
            <li>Ausgang</li>
          </ul>
        </div>

        {/* Interessen */}
        <div className="side-panel__data-item">
          <div className="side-panel__data-item-title" >
            <img src={ interessenIcon } alt="" />
            <Link to="interes">Interessen</Link>
          </div>
        </div>

        {/* Einstellungen */}
        <div className="side-panel__data-item">
          <div className="side-panel__data-item-title">
            <Link to="profile">
              <img src={ einstellungenIcon } alt="" />
              Einstellungen
            </Link>
          </div>
          <ul className="side-panel__data-item-list">
            <li>Profil</li>
            <li>Passwort</li>
            <li>E-Mail</li>
          </ul>
        </div>

        {/* Hilfe */}
        <div className="side-panel__data-item">
          <div className="side-panel__data-item-title">
            <img src= { hilfeIcon } alt="" />
            <a>Hilfe</a>
          </div>
        </div>
        
      </div>
    </div>
  );
};


export default SidePanel;