import React, {useState, useEffect } from "react";
import './TopicCardProgressBar.css';


const TopicCardProgressBar = (props) => {
  const { progress = 0 } = props;

  const [ percent, setPercent ] = useState(progress);
  useEffect (() => setPercent(progress), [progress] );

  let styleOuter = {
    opacity: 1,
  };

  let styleInner = {
    width: percent + '%',
    opacity: 1,
  };

  if (percent < 3) {
    styleOuter.opacity = 0;
    styleInner.opacity = 0;
  }

  if (percent > 95) {
    styleInner.width = '100%';
    styleOuter.background = 'var(--color-primary-2)';
  }



  return (
    <div className="topic-card__progress-bar-outer" style={ styleOuter }>
      <div className="topic-card__progress-bar-inner" style={styleInner}></div>
    </div>
  )
};


export default TopicCardProgressBar;