import React, { useState } from 'react';
import { connect } from 'react-redux';
import Btn from '../Btn';
import './InteresForm.css';



const InteresForm = (props) => {
  const { 
    themes, 
    dispatchNewSettings 
  } = props;

  if (!themes) return;

  const formItems = themes
    .map(({ themeName, isActive, isPermanent }) => {
      return (
      <InteresFormItem 
        themeName={ themeName } 
        isActive={ isActive }
        isPermanent={ isPermanent }
        key={ themeName }
      />
    );
  });

  const onSubmitFunction = ({ target }) => {
    const inputs = [...target.form.querySelectorAll('input')];
    const data = inputs
      .reduce((acc, el) => {
        acc.push({ themeName: el.name, isActive: el.checked });
        return acc;
      }, []);
    dispatchNewSettings(data);
  };

  return (
    <form className='interes-form'>
      <h2 className='interes-form__title'>Welche Unternehmensbereiche interessieren Dich?</h2>

      <div className='interes-form__options-wrapper'>
        { formItems }
      </div>

      <Btn 
        addClass={ 'settings-form__btn' } 
        text={ 'speichern' }
        onClickFunction={ onSubmitFunction }
      />
    </form>
  );
};


function InteresFormItem(props) {
  const {
    themeName = 'No Theme',
    isActive = false,
    isPermanent = false,
    addClassName= ''
  } = props;

  const [optionStatus, setOptionStatus] = useState(isActive);
  const onChangeFunction = isPermanent ? 
    (ev) => ev.preventDefault() : 
    (ev) => {
      setOptionStatus(ev.target.checked);
  };

  // отключить onClick событие для "неотключаемых" тем
  const onClickFunction = isPermanent ?
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
    } : () => {};


  return (
    <label className={ 'interes-form__option-item ' + (optionStatus ? 'active ' : '') + (addClassName) }>      
      
      <div className='interes-form__circle'></div>
      { themeName }

      <input 
        className='interes-form__checkbox' 
        type="checkbox" 
        name={ themeName } 
        value={ themeName }
        onChange={ isPermanent ? (ev) => {
          ev.preventDefault();
          console.log("can't switch");
        } : onChangeFunction }
        defaultChecked={ optionStatus }
        onClick={ onClickFunction }
      />
    </label>
  );
}

const mapStateToProps = (state) => ({ 
  themes: state.themes
});

const mapDispatchToProps = (dispatch) => ({
  dispatchNewSettings: (payload) => dispatch({ type: 'set_interest_data', payload })
});


export default connect(mapStateToProps, mapDispatchToProps)(InteresForm);