const textData = {
  culture1: {
    title: 'Welcome on Board',
    body: `Schön, dass du da bist. Ich begrüße dich ganz herzlich bei uns: Von mir erfährst du woher wir kommen und vor allem, was wir als Unternehmen noch vorhaben.<br><br>
    Kurz zu mir: Das Klischee stimmt ein bisschen: Ich sitze meistens in einem Elfenbeinturm oder einer Besprechung und bin auch ziemlich oft außer Haus unterwegs.<br><br>
    Mein Job als CEO ist es, unser Unternehmen ganz vorne im Markt zu platzieren und mit unserer gemeinsamen Arbeit und unseren Produkten die Welt ein bisschen besser zu machen. Kurz zu mir: Als CEO übernehme ich üblicherweise Leitungsaufgaben und entscheide letztlich über alle wichtigen Unternehmensaktivitäten.<br><br> 
    Trotzdem: Für dich bin dein Kollege und immer gerne ansprechbar. Mit richtig guten Ideen darf man bei mir gerne offene Türen einrennen. Ich wünsche dir einen guten Start bei uns und freue mich auf gute Zusammenarbeit.`
  },

  culture5: {
    title: "You'll Never walk Allone",
    body: `Diversity ist in aller Munde. Wie agil wir hier damit umgehen, und was genau meine Aufgabe als ehrenamtlicher Alyship-Coach ist, erfährt du hier. Eigentlich mache ich ja etwas komplett anderes. Aber als ich von der Möglichkeit erfahren habe, Menschen mit meinen Erfahrungen und Skills nachhaltig unterstützen zu können, bin ich in das Programm eingestiegen. Was ist Allyship? Allyship ist eine aktive, konsequente und anstrengende Praxis des Verlernens und Neubewertens, bei der eine Person in einer privilegierten und machtvollen Position versucht, in Solidarität mit einer Randgruppe zu handeln. Allyship ist keine Identität - es ist ein lebenslanger Prozess des Aufbaus von Beziehungen auf der Grundlage von Vertrauen, Beständigkeit und Verantwortlichkeit mit marginalisierten Einzelpersonen und/oder Gruppen von Menschen. Allyship ist keine Selbstdefinition - die Arbeit und die Bemühungen von Verbündeten müssen von den Menschen, mit denen sie sich verbünden wollen, anerkannt werden.`
  },

  culture7: {
    title: 'Kultur in 5 Lektionen',
    body: `Unser kleiner Spoiler, die erste Regel, klingt zwar paradox – ist sie aber nicht. Unsere policy ist so flexibel, wie wir sie brauchen. Und: wir machen es ziemlich einfach: 5 Worte reichen, dass unsere Leute fantastische Sachen machen: „Handele in Netflixes bestem Interesse.“ Unsere gesamte Unternehmenskultur ist deshalb so erfolgreich, weil wir ständig an ihr arbeiten. Warum wir in unserem Job mehr inspirieren und Freiheiten geben als managen müssen – und warum das entscheidend dazu beitragen, um unsere Kunden zufriedenstellen zu können … sehen sie hier.`
  },

  culture11: {
    title: 'Bei unserem Quiz.',
    body: `Mit unserem kleinen Quiz kannst du testen, 
    was du schon alles weißt. Und das lohnt sich. 
    Denn damit kannst du dir, bevor du überhaupt bei 
    uns anfängst, sogar ein paar zusätzliche Urlaubstage 
    herausholen.<br><br>
     
    Viel Spaß<br><br>

    Dein HR-Team`
  },


  
  people3: {
    title: 'Ein Team für alle Fälle',
    body: `Willkommen im Auge des Sturms. Ich zeige dir, wie meine Abteilung
    den Content für unsere Marke schafft und international bereitstellt. 
    Und Content kommt nicht von alleine. Und sieht auch nicht von alleine gut aus.
    Wir arbeiten hart daran, alles, was die positive Wahrnehmung unserer Marke fördert 
    zu sammeln, aufzubereiten und auf den passenden Kanälen zu präsentieren.
    Das ist immer eine Herausforderung, manchmal anstrengend, aber es macht immer richtig Spaß. Besonders gefällt mir dabei der Austausch mit vielen Gewerken und Abteilungen innerhalb des Unternehmens – und die Chancen stehen nicht schlecht, dass du mir bald in echt begegnen wirst.`
  },

  people6: {
    title: 'Leute, was habt ihr gemeinsam?',
    body: `Ich fühle Puls, ich lese Gedanken, aber vor allem höre ich zu und sorge dafür, dass es euch gut geht – egal was ihr bei uns arbeitet. Ich suche nämlich nicht nur nach neuen Talenten da draußen, ich schaue auch, wo eure Stärken und Entwicklungs-möglichkeiten liegen. Mitarbeitenden neue Türen zu öffnen oder zum Beispiel Weiterbildungen zu organisieren gehört zum Beispiel dazu. Last but not least kümmere mich auch darum, dass es allen Mitarbeitenden in unserem Unternehmen gut geht. Für alle, die etwas auf dem Herzen haben, für die steht meine Tür grundsätzlich immer offen.`
  },
};


export default textData;