import React from 'react';
import Player from '../Player';
// import wellcomeVideoSrc from 
// import { video4 as wellcomeVideoSrc } from '../../assets/video/videoData'; // это должно быть приветственное видео
import { connect } from 'react-redux';
import './WellcomeVideo.css';


const WellcomeVideo = (props) => {
  const { 
    isWelcomeVideoShown,
    dispatchWellcomeVideoShown,
    dispatchSetActiveCardName,
  } = props;

  return undefined;
    
    if (isWelcomeVideoShown) {
      return;
    }
    
    document.body.classList.add('fullscreen');
  return (
    <div className='wellcome'>

      closeCross

      {/* <Player src={ wellcomeVideoSrc } /> */}
      <button onClick={ () => {
        document.body.classList.remove('fullscreen');
        dispatchWellcomeVideoShown();
        dispatchSetActiveCardName('culture1');
        document.querySelector('.wellcome').innerHTML = '';
        document.querySelector('.wellcome').style.display = 'none';

        }
      }>Close</button>
    </div>
  )
};


const mapDispatchToProps = (dispatch) => ({
  dispatchSetActiveCardName: () => dispatch({ type: 'set_active_card', payload: 'culture1' }),
  dispatchWellcomeVideoShown: () => dispatch({ type: 'set_wellcome_video_shown' })
})

export default connect( ({ isWelcomeVideoShown }) => ({isWelcomeVideoShown}), mapDispatchToProps )(WellcomeVideo);