import React, { useEffect, useState } from 'react';
import './TopicCard.css';

import TopicCardProgressBar from '../TopicCardProgressBar';

import defaultImg from './images/default-img.png';
// import lockIcon from './images/card-lock.png';
import lockIcon from './images/lock-new.png';
import pixelIcon from './images/card-pixel.png';

import { connect } from 'react-redux';


const TopicCard = (props) => {
  const {
    imgSrc = defaultImg,   // изображение
    status = '',           // открыто / blocked / active
    name = '',
    dispatchActiveCard,
    dispatchActualTheme,
    dispatchResetNewFromSomeCards,
    dispatchMaxPercent,
    dispatchOpenNextCards,

    cardsData2,
    store
  } = props;


  const thisCard = cardsData2.find((card) => card.name === name);

  useEffect(
    () => {
      const card = document.querySelector(`.${name}`);
      name === store.activeCard ? card.classList.add('active') : card.classList.remove('active');
      thisCard.isNew ? card.classList.add('new') : card.classList.remove('new');
    }, [store]
  )

  const [ percent, setPercent ] = useState(thisCard?.viewedPercent ?? 0);
  // const [ isActive, setActive ] = useState()

  useEffect( () => {
    setPercent(thisCard?.viewedPercent ?? 0);
  }, [store]);


  const onCardClick = (ev) => {
    const target = ev.target;
    if (target.closest('.topic-card').classList.contains('blocked')) return;

    dispatchActiveCard(name);

    // клик по карте с опросником
    if (target.closest('.topic-card').classList.contains('culture11')) {
      dispatchResetNewFromSomeCards(thisCard.resetNewStatusCards);
      dispatchMaxPercent({name: 'culture10', percent: 65 });
      dispatchMaxPercent({name: 'culture9', percent: 30 });
      dispatchMaxPercent({name: 'culture8', percent: 80 });
      dispatchOpenNextCards(thisCard.nextMedia);
    }

    // поднять активный топик в раздел Aktuelles Thema
    const newActualSection = target.closest('.topic-theme-section');
    if (window.pageYOffset > 300) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
    const oldActualSection = document.getElementById('actual-theme')?.querySelector('.topic-theme-section');
    if (!newActualSection || !oldActualSection) return;
    if (newActualSection === oldActualSection) return;

    const moveActiveSection = (newActual, oldActual) => {
      newActual.classList.add('actual');
      oldActual.classList.remove('actual');
      dispatchActualTheme(newActual.dataset.themeName);
    }

    [newActualSection, oldActualSection].forEach((section) => {
      section.classList.add('smooth-hide');
    });

    setTimeout(() => {
        // убрать прозрачность
        [newActualSection, oldActualSection].forEach((section) => {
          section.classList.remove('smooth-hide');
        });
        // поменять местами
        moveActiveSection(newActualSection, oldActualSection);
      }, 500);

    if (window.pageYOffset > 300) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  return (
    <div className={ `topic-card ${status} ${name}` } onClick={ thisCard.fake ? () => {} : onCardClick }>
      <img className="topic-card__lock-icon" src={ lockIcon } alt="" />
      <img className="topic-card__pixel-icon" src={ pixelIcon } alt="" />
      <img className="topic-card__img" src={ imgSrc } alt="" />
      <TopicCardProgressBar progress={ percent }/>
    </div>
  )
};



const mapStateToProps = (store) => ({ 
  cardsData2: store.cardsData,
  store: store
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchActiveCard:  (payload) => dispatch({ type: 'set_active_card', payload }),
    dispatchActualTheme: (payload) => dispatch({ type: 'set_actual_theme', payload }),
    dispatchResetNewFromSomeCards: (payload) => dispatch({type: 'reset_new_from_some_cards', payload }),
    dispatchMaxPercent: (payload) => dispatch({ type: 'update_card_percent', payload }),
    dispatchOpenNextCards: (payload) => {
      dispatch({ type: 'unlock_cards', payload });
    },
  }
};

// const mapDispatchToProps = (dispatch) => ({
  
// })

 export default connect(mapStateToProps, mapDispatchToProps)(TopicCard);