import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import './InvitationToSettings.css';
import Btn from '../Btn';

const InvitationToSettings = (props) => {
  const {
    isInvitationToSettingsShown,
    dispatchInvitationShown,
    dispatchUnlockCards
  } = props;

  const navigate = useNavigate();
  if (isInvitationToSettingsShown) return;

  document.body.classList.add('fullscreen');


  const onInvitationClick = () => {
    dispatchInvitationShown();
    dispatchUnlockCards(['culture2', 'culture3', 'culture4', 'culture5']);
    document.body.classList.remove('fullscreen');
    navigate('/settings/profile');
  };

  return (
    <div className='invitation'>
      
      <div className='invitation__dialog-window'>
        {/* <h2 className='invitation__title'>Bevor es los geht</h2> */}
        <div className='invitation__sub-title'>Bevor du startest, vervollständige<br/> bitte noch dein Profil.</div>

        <Btn addClass='invitation__btn btn--white' text='Los geht’s!' onClickFunction={ onInvitationClick } />

      </div>

    </div>
  )
}

const mapStateToProps = ({ isInvitationToSettingsShown }) => ({ isInvitationToSettingsShown });
const mapDispatchToProps = (dispatch) => ({
  dispatchInvitationShown: () => dispatch({ type: 'set_invitation_to_settings' }),
  dispatchUnlockCards: (payload) => dispatch({ type: 'unlock_cards', payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationToSettings);