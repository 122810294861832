import images from "../services/imageService"
import * as videos from '../../src/assets/video/videoData';



// culture
// ----------
const cultureCardsData = [
  {
    name:          'culture1',
    theme:         'culture',
    type:          'video',
    status:        'unblocked',
    imgSrc:        images.cultureImg1,
    src:           videos.culture1,
    poster:        images.culturePoster1,
    // nextMedia:     ['culture2', 'culture3', 'culture4', 'culture5'],
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    isNew:         false,
  },
  {
    name:          'culture2',
    theme:         'culture',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.cultureImg2,
    poster:        images.culturePoster2,
    src:           '',
    nextMedia:     null,
    maxViewedTime: 100,
    viewedPercent: 100,
    isNew:         false,
    fake:          true,
  },
  {
    name:          'culture3',
    theme:         'culture',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.cultureImg3,
    poster:        images.culturePoster3,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 100,
    viewedPercent: 100,
    fake:          true,
    isNew:         false,
  },
  {
    name:          'culture4',
    theme:         'culture',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.cultureImg4,
    poster:        images.culturePoster4,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 100,
    viewedPercent: 10,
    fake:          true,
    isNew:         false,
  },
  {
    name:          'culture5',
    theme:         'culture',
    type:          'video',
    src:           videos.culture5,
    imgSrc:        images.cultureImg5,
    poster:        images.culturePoster5,
    maxViewedTime: 0,
    viewedPercent: 0,
    status:       'blocked',
    nextMedia:    ['culture6', 'culture7'],
    fake:         false,
    isNew:        true,
  },
  {
    name:          'culture6',
    theme:         'culture',
    type:          'video',
    status:        'blocked',
    src:           null,
    imgSrc:        images.cultureImg6,
    poster:        images.culturePoster6,
    maxViewedTime: 0,
    viewedPercent: 0,
    nextMedia:     null,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'culture7',
    theme:         'culture',
    type:          'video',
    status:        'blocked',
    src:           videos.culture7,
    imgSrc:        images.cultureImg7,
    poster:        images.culturePoster7,
    maxViewedTime: 0,
    viewedPercent: 0,
    nextMedia:     ['culture8', 'culture9', 'culture10', 'culture11'],
    resetNewStatusCards: ['culture6'],  // захардкоженное значение, у кого удалить статус isNew
    fake:          false,
    isNew:         true,
  },
  {
    name:          'culture8',
    theme:         'culture',
    type:          'video',
    status:        'blocked',
    src:           null,
    imgSrc:        images.cultureImg8,
    poster:        images.culturePoster8,
    maxViewedTime: 0,
    viewedPercent: 0,
    nextMedia:     null,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'culture9',
    theme:         'culture',
    type:          'video',
    status:        'blocked',
    src:           null,
    imgSrc:        images.cultureImg9,
    poster:        images.culturePoster9,
    maxViewedTime: 0,
    viewedPercent: 0,
    nextMedia:     null,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'culture10',
    theme:         'culture',
    type:          'video',
    status:        'blocked',
    src:           null,
    imgSrc:        images.cultureImg10,
    poster:        images.culturePoster10,
    maxViewedTime: 0,
    viewedPercent: 0,
    nextMedia:     null,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'culture11',
    theme:         'culture',
    type:          'quiz',
    status:        'blocked',
    src:           null,
    imgSrc:        images.cultureImg11,
    maxViewedTime: 0,
    viewedPercent: 0,
    nextMedia:     ['people1', 'people2', 'people3'],
    resetNewStatusCards: ['culture8', 'culture9', 'culture10', 'culture11'],  // захардкоженное значение, у кого удалить статус isNew
    fake:          false,
    isNew:         true,
  },
];


// people
// ----------
const peopleCardsData = [
  {
    name:          'people1',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg1,
    poster:        images.peoplePoster1,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'people2',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg2,
    poster:        images.peoplePoster2,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'people3',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg3,
    poster:        images.peoplePoster3,
    src:           videos.people3,
    nextMedia:     ['people4', 'people5', 'people6'],
    resetNewStatusCards: ['people1', 'people2'],  // захардкоженное значение, у кого удалить статус isNew
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          false,
    isNew:         true,
  },
  {
    name:          'people4',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg4,
    poster:        images.peoplePoster4,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'people5',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg5,
    poster:        images.peoplePoster5,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'people6',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg6,
    poster:        images.peoplePoster6,
    src:           videos.people6,
    nextMedia:     ['people7', 'people8'],
    resetNewStatusCards: ['people4', 'people5'],  // захардкоженное значение, у кого удалить статус isNew
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          false,
    isNew:         true,
  },
  {
    name:          'people7',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg7,
    poster:        images.peoplePoster7,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'people8',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg8,
    poster:        images.peoplePoster8,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'people9',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg9,
    poster:        images.peoplePoster9,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'people10',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg10,
    poster:        images.peoplePoster10,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'people11',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg11,
    poster:        images.peoplePoster11,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'people12',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg12,
    poster:        images.peoplePoster12,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'people13',
    theme:         'people',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.peopleImg13,
    poster:        images.peoplePoster13,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  }
];



// legal
// ----------
const legalCardsData = [
  {
    name:          'legalCard1',
    theme:         'legal',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.legalImg1,
    poster:        images.legalPoster1,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'legalCard2',
    theme:         'legal',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.legalImg2,
    poster:        images.legalPoster2,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'legalCard3',
    theme:         'legal',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.legalImg3,
    poster:        images.legalPoster3,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'legalCard4',
    theme:         'legal',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.legalImg4,
    poster:        images.legalPoster4,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'legalCard5',
    theme:         'legal',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.legalImg5,
    poster:        images.legalPoster5,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'legalCard6',
    theme:         'legal',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.legalImg6,
    poster:        images.legalPoster6,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'legalCard7',
    theme:         'legal',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.legalImg7,
    poster:        images.legalPoster7,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },
  {
    name:          'legalCard8',
    theme:         'legal',
    type:          'video',
    status:        'blocked',
    imgSrc:        images.legalImg8,
    poster:        images.legalPoster8,
    src:           null,
    nextMedia:     null,
    maxViewedTime: 0,
    viewedPercent: 0,
    fake:          true,
    isNew:         true,
  },

  
];




// business
// ----------
const businessCardsData = [
  {
    src:  null,
    maxViewedTime: 0,
    viewedPercent: 0,
    imgSrc:        images.businessImg1,
    status:       'blocked',
    theme:        'business',
    name:         'business1',
    nextMedia:    null,
    fake:          true,
  },
  {
    src:  null,
    maxViewedTime: 0,
    viewedPercent: 0,
    imgSrc:        images.businessImg2,
    status:       'blocked',
    theme:        'business',
    name:         'business2',
    nextMedia:    null,
    fake:          true,
  },
  {
    src:  null,
    maxViewedTime: 0,
    viewedPercent: 0,
    imgSrc:        images.businessImg3,
    status:       'blocked',
    theme:        'business',
    name:         'business3',
    nextMedia:    null,
    fake:          true,
  },
  {
    src:  null,
    maxViewedTime: 0,
    viewedPercent: 0,
    imgSrc:        images.businessImg4,
    status:       'blocked',
    theme:        'business',
    name:         'business4',
    nextMedia:    null,
    fake:          true,
  },
  {
    src:  null,
    maxViewedTime: 0,
    viewedPercent: 0,
    imgSrc:        images.businessImg5,
    status:       'blocked',
    theme:        'business',
    name:         'business4',
    nextMedia:    null,
    fake:          true,
  },
  {
    src:  null,
    maxViewedTime: 0,
    viewedPercent: 0,
    imgSrc:        images.businessImg6,
    status:       'blocked',
    theme:        'business',
    name:         'business4',
    nextMedia:    null,
    fake:          true,
  },
  {
    src:  null,
    maxViewedTime: 0,
    viewedPercent: 0,
    imgSrc:        images.businessImg7,
    status:       'blocked',
    theme:        'business',
    name:         'business4',
    nextMedia:    null,
    fake:          true,
  },
  {
    src:  null,
    maxViewedTime: 0,
    viewedPercent: 0,
    imgSrc:        images.businessImg8,
    status:       'blocked',
    theme:        'business',
    name:         'business4',
    nextMedia:    null,
    fake:          true,
  },
];


export default [
  ...legalCardsData, 
  ...cultureCardsData,
  ...peopleCardsData,
  ...businessCardsData,

  {
    src:  null,
    maxViewedTime: 0,
    viewedPercent: 0,
    imgSrc:        images.businessImg4,
    status:       'blocked',
    theme:        null,
    name:         'wellcome',
    nextMedia:    null
  }
];