import React from 'react';
import './Btn.css';

const Btn = (props) => {
  const { 
    text = 'button',
    addClass = '',
    onClickFunction = () => console.log('btn pressed!')
  } = props;
  
  const onClick = (ev) => {
    ev.preventDefault();
    onClickFunction(ev);
  } 

  return(
    <button
      className={ 'btn ' + addClass } 
      onClick={ onClick }>
      { text }
    </button>
  )
};


export default Btn;