// import cultureImg1 from '../assets/images/topic-cards/culture-1.png';
// import cultureImg2 from '../assets/images/topic-cards/culture-2.png';
// import cultureImg3 from '../assets/images/topic-cards/culture-3.png';
// import cultureImg4 from '../assets/images/topic-cards/culture-4.png';
// import cultureImg5 from '../assets/images/topic-cards/culture-5.png';
// import cultureImg6 from '../assets/images/topic-cards/culture-6.png';

// import peopleImg1 from '../assets/images/topic-cards/people-1.png';
// import peopleImg2 from '../assets/images/topic-cards/people-2.png';
// import peopleImg3 from '../assets/images/topic-cards/people-3.png';
// import peopleImg4 from '../assets/images/topic-cards/people-4.png';

// import legalImg1 from '../assets/images/topic-cards/legal-1.png';
// import legalImg2 from '../assets/images/topic-cards/legal-2.png';
// import legalImg3 from '../assets/images/topic-cards/legal-3.png';
// import legalImg4 from '../assets/images/topic-cards/legal-4.png';

// import businessImg1 from '../assets/images/topic-cards/business-1.png';
// import businessImg2 from '../assets/images/topic-cards/business-2.png';
// import businessImg3 from '../assets/images/topic-cards/business-3.png';
// import businessImg4 from '../assets/images/topic-cards/business-4.png';

// culture
import cultureImg1 from '../assets/images/topic-cards/culture/culture1.jpg';
import cultureImg2 from '../assets/images/topic-cards/culture/culture2.jpg';
import cultureImg3 from '../assets/images/topic-cards/culture/culture3.jpg';
import cultureImg4 from '../assets/images/topic-cards/culture/culture4.jpg';
import cultureImg5 from '../assets/images/topic-cards/culture/culture5.jpg';
import cultureImg6 from '../assets/images/topic-cards/culture/culture6.jpg';
import cultureImg7 from '../assets/images/topic-cards/culture/culture7.jpg';
import cultureImg8 from '../assets/images/topic-cards/culture/culture8.jpg';
import cultureImg9 from '../assets/images/topic-cards/culture/culture9.jpg';
import cultureImg10 from '../assets/images/topic-cards/culture/culture10.jpg';
import cultureImg11 from '../assets/images/topic-cards/culture/culture11.jpg';

import culturePoster1 from '../assets/images/topic-cards/culture-poster/culture1.jpg';
import culturePoster2 from '../assets/images/topic-cards/culture-poster/culture2.jpg';
import culturePoster3 from '../assets/images/topic-cards/culture-poster/culture3.jpg';
import culturePoster4 from '../assets/images/topic-cards/culture-poster/culture4.jpg';
import culturePoster5 from '../assets/images/topic-cards/culture-poster/culture5.jpg';
import culturePoster6 from '../assets/images/topic-cards/culture-poster/culture6.jpg';
import culturePoster7 from '../assets/images/topic-cards/culture-poster/culture7.jpg';
import culturePoster8 from '../assets/images/topic-cards/culture-poster/culture8.jpg';
import culturePoster9 from '../assets/images/topic-cards/culture-poster/culture9.jpg';
import culturePoster10 from '../assets/images/topic-cards/culture-poster/culture10.jpg';
import culturePoster11 from '../assets/images/topic-cards/culture-poster/culture11.jpg';


// people
import peopleImg1 from '../assets/images/topic-cards/people/people1.jpg';
import peopleImg2 from '../assets/images/topic-cards/people/people2.jpg';
import peopleImg3 from '../assets/images/topic-cards/people/people3.jpg';
import peopleImg4 from '../assets/images/topic-cards/people/people4.jpg';
import peopleImg5 from '../assets/images/topic-cards/people/people5.jpg';
import peopleImg6 from '../assets/images/topic-cards/people/people6.jpg';
import peopleImg7 from '../assets/images/topic-cards/people/people7.jpg';
import peopleImg8 from '../assets/images/topic-cards/people/people8.jpg';
import peopleImg9 from '../assets/images/topic-cards/people/people9.jpg';
import peopleImg10 from '../assets/images/topic-cards/people/people10.jpg';
import peopleImg11 from '../assets/images/topic-cards/people/people11.jpg';
import peopleImg12 from '../assets/images/topic-cards/people/people12.jpg';
import peopleImg13 from '../assets/images/topic-cards/people/people13.jpg';

import peoplePoster1 from '../assets/images/topic-cards/people-poster/people1.jpg';
import peoplePoster2 from '../assets/images/topic-cards/people-poster/people2.jpg';
import peoplePoster3 from '../assets/images/topic-cards/people-poster/people3.jpg';
import peoplePoster4 from '../assets/images/topic-cards/people-poster/people4.jpg';
import peoplePoster5 from '../assets/images/topic-cards/people-poster/people5.jpg';
import peoplePoster6 from '../assets/images/topic-cards/people-poster/people6.jpg';
import peoplePoster7 from '../assets/images/topic-cards/people-poster/people7.jpg';
import peoplePoster8 from '../assets/images/topic-cards/people-poster/people8.jpg';
import peoplePoster9 from '../assets/images/topic-cards/people-poster/people9.jpg';
import peoplePoster10 from '../assets/images/topic-cards/people-poster/people10.jpg';
import peoplePoster11 from '../assets/images/topic-cards/people-poster/people11.jpg';
import peoplePoster12 from '../assets/images/topic-cards/people-poster/people12.jpg';
import peoplePoster13 from '../assets/images/topic-cards/people-poster/people13.jpg';


// legal
import legalImg1 from '../assets/images/topic-cards/legal/legal1.jpg';
import legalImg2 from '../assets/images/topic-cards/legal/legal2.jpg';
import legalImg3 from '../assets/images/topic-cards/legal/legal3.jpg';
import legalImg4 from '../assets/images/topic-cards/legal/legal4.jpg';
import legalImg5 from '../assets/images/topic-cards/legal/legal5.jpg';
import legalImg6 from '../assets/images/topic-cards/legal/legal6.jpg';
import legalImg7 from '../assets/images/topic-cards/legal/legal7.jpg';
import legalImg8 from '../assets/images/topic-cards/legal/legal8.jpg';

import legalPoster1 from '../assets/images/topic-cards/legal-poster/legal1.jpg';
import legalPoster2 from '../assets/images/topic-cards/legal-poster/legal2.jpg';
import legalPoster3 from '../assets/images/topic-cards/legal-poster/legal3.jpg';
import legalPoster4 from '../assets/images/topic-cards/legal-poster/legal4.jpg';
import legalPoster5 from '../assets/images/topic-cards/legal-poster/legal5.jpg';
import legalPoster6 from '../assets/images/topic-cards/legal-poster/legal6.jpg';
import legalPoster7 from '../assets/images/topic-cards/legal-poster/legal7.jpg';
import legalPoster8 from '../assets/images/topic-cards/legal-poster/legal8.jpg';


// business
import businessImg1 from '../assets/images/topic-cards/business/business1.jpg';
import businessImg2 from '../assets/images/topic-cards/business/business2.jpg';
import businessImg3 from '../assets/images/topic-cards/business/business3.jpg';
import businessImg4 from '../assets/images/topic-cards/business/business4.jpg';
import businessImg5 from '../assets/images/topic-cards/business/business5.jpg';
import businessImg6 from '../assets/images/topic-cards/business/business6.jpg';
import businessImg7 from '../assets/images/topic-cards/business/business7.jpg';
import businessImg8 from '../assets/images/topic-cards/business/business8.jpg';

import businessPoster1 from '../assets/images/topic-cards/business-poster/business1.jpg';
import businessPoster2 from '../assets/images/topic-cards/business-poster/business2.jpg';
import businessPoster3 from '../assets/images/topic-cards/business-poster/business3.jpg';
import businessPoster4 from '../assets/images/topic-cards/business-poster/business4.jpg';
import businessPoster5 from '../assets/images/topic-cards/business-poster/business5.jpg';
import businessPoster6 from '../assets/images/topic-cards/business-poster/business6.jpg';
import businessPoster7 from '../assets/images/topic-cards/business-poster/business7.jpg';
import businessPoster8 from '../assets/images/topic-cards/business-poster/business8.jpg';


const images = {
  // culture
  cultureImg1,
  cultureImg2,
  cultureImg3,
  cultureImg4,
  cultureImg5,
  cultureImg6,
  cultureImg7,
  cultureImg8,
  cultureImg9,
  cultureImg10,
  cultureImg11,

  culturePoster1,
  culturePoster2,
  culturePoster3,
  culturePoster4,
  culturePoster5,
  culturePoster6,
  culturePoster7,
  culturePoster8,
  culturePoster9,
  culturePoster10,
  culturePoster11,

  // people
  peopleImg1,
  peopleImg2,
  peopleImg3,
  peopleImg4,
  peopleImg5,
  peopleImg6,
  peopleImg7,
  peopleImg8,
  peopleImg9,
  peopleImg10,
  peopleImg11,
  peopleImg12,
  peopleImg13,

  peoplePoster1,
  peoplePoster2,
  peoplePoster3,
  peoplePoster4,
  peoplePoster5,
  peoplePoster6,
  peoplePoster7,
  peoplePoster8,
  peoplePoster9,
  peoplePoster10,
  peoplePoster11,
  peoplePoster12,
  peoplePoster13,

  // legal
  legalImg1,
  legalImg2,
  legalImg3,
  legalImg4,
  legalImg5,
  legalImg6,
  legalImg7,
  legalImg8,

  legalPoster1,
  legalPoster2,
  legalPoster3,
  legalPoster4,
  legalPoster5,
  legalPoster6,
  legalPoster7,
  legalPoster8,

  // business
  businessImg1,
  businessImg2,
  businessImg3,
  businessImg4,
  businessImg5,
  businessImg6,
  businessImg7,
  businessImg8,

  businessPoster1,
  businessPoster2,
  businessPoster3,
  businessPoster4,
  businessPoster5,
  businessPoster6,
  businessPoster7,
  businessPoster8
};


export default images;