// функции для работы с карточками

export default class CardsService {

  getCardByName = (name, arr) => {
    return arr.find((card) => card.name === name);
  }

  getCardsByTheme = (theme, arr) => {
    return arr.filter((card) => card.theme === theme);
  }

  getThemeCardsCount = (theme, cards) => {
    return cards.filter((card) => card.theme === theme).length;
  }

  getTimeInPercent = (currentTime, duration) => {
    return Math.floor((100 / duration) * currentTime);
  }

  // для Store
  setMaxViewedTime = (state, payload) => {
    const { name, maxViewedTime, viewedPercent } = payload;
    if (!name || !maxViewedTime || !viewedPercent ) return state;
    
    const newState = {...state};
    const card = this.getCardByName(name, newState.cardsData);
    card.maxViewedTime = maxViewedTime;
    card.viewedPercent = viewedPercent;
    return newState;
  };

  setPercent = (state, payload) => {
    const { name, percent } = payload;
    const newState = {...state};
    const card = this.getCardByName(name, newState.cardsData);
    card.viewedPercent = percent;
    return newState;
  }

  setActiveCard = (state, payload) => {
    return {...state, activeCard: payload };
  }

  unlockCards = (state, payload) => {
    const namesToUnlock = payload;
    if (!namesToUnlock || namesToUnlock.length === 0) return state;
    const newState = {...state};

    namesToUnlock
      .forEach((name) => {
        const unlockCard = this.getCardByName(name, newState.cardsData);
        if (unlockCard.status === 'unlocked') return;
        unlockCard.status = 'unlocked';
    });
    namesToUnlock.forEach((name) => document.querySelector(`.${name}`)?.classList.remove('blocked'));
    return newState;
  }

  resetNew = (state, payload) => {
    const newState = { ...state }; 
    const card = this.getCardByName(payload, newState.cardsData);
    if (!card.isNew) return;
    card.isNew = false;
    return newState;
  }

  resetNewFromSomeCards = (state, payload) => {
    const newState = { ...state };
    const cardNames = payload;
    if (!cardNames || cardNames.length === 0) return;
    cardNames.forEach((cardName) => {
      const card = this.getCardByName(cardName, newState.cardsData);
      if (!card.isNew) return;
      card.isNew = false;
    });
    return newState;
  }

}



// const setCardVisible = (state, action) => {
//   const targetName = action.payload;
//   const storeIndex = state.cardsData.findIndex((el) => el.name === targetName);

//   const newCardData = {...state.cardsData[storeIndex]};
//   newCardData.status = 'visible';

//   const newCardsData = [
//     ...state.cardsData.slice(0, storeIndex,),
//     newCardData,
//     ...state.cardsData.slice(storeIndex + 1)
//   ];
//   return { ...state, cardsData: newCardsData };
// };