import React, {useState, useEffect } from 'react';
import Briefing from '../../components/Briefing/';
import TopicThemeSection from '../../components/TopicThemeSection';
import InvitationToSettings from '../../components/InvitationToSettings';
// import InvitationToSettings from '../../InvitationToSettings'
import WellcomeVideo from '../../components/WellcomeVideo';
import './DashboardPage.css';

import { connect } from 'react-redux';


const DashboardPage = (props) => {
  const { 
    isWelcomeVideoShown, 
    isInvitationToSettingsShown,
    dispatchNewActualTheme,
    actualTheme
  } = props;

  // const [ themes, setThemes ] = useState(props.themes);
  // props.themes;

  // useEffect(() => setThemes(props.themes), [props]);

  const actualTopicSection = [];
  const restTopicsSection = [];
  const [ isFirstTimeClicked, setFirstTimeClicked ] = useState(false);

  props.themes.forEach((theme) => {
    if (!theme.themeName === actualTheme) return;
    const component = (
      <TopicThemeSection 
        key={theme.themeName} 
        theme={ theme.themeName }
      />
    );

    if (theme.themeName === actualTheme) {
      actualTopicSection.push(component);
      return;
    }
    if (theme.isActive) {
      restTopicsSection.push(component);
    }
    return;
  });


  let onDashboardClick = () => setFirstTimeClicked(true);

  
  
  return (
    <section className="dashboard" >

      
      { isWelcomeVideoShown && !isInvitationToSettingsShown && !isFirstTimeClicked ? <InvitationMask onClickFunction={ onDashboardClick } /> : undefined }
      
      { isWelcomeVideoShown && !isInvitationToSettingsShown && isFirstTimeClicked ? <InvitationToSettings /> : undefined }

      <div className="dashboard__media-content">
        <Briefing />
      </div>

      <div className="dashboard__inner">
        <div className='dashboard__actual-theme'>
          <h2 className='dashboard__actual-theme-title'>Aktuelles Thema</h2>
        </div>
      </div>

      <div className='overflow-container'>
        <div className='dashboard__inner dashboard__inner-topic-section visible' id='actual-theme'>
          { actualTopicSection }
        </div>
      </div>

      <div className="dashboard__inner">
        <div className='dashboard__actual-theme'>
          <h2 className='dashboard__actual-theme-title'>Weitere Themen</h2>
        </div>
      </div>

      <div className='overflow-container'>
        <div className='dashboard__inner dashboard__inner-topic-section visible' id='usual-themes'>
          { restTopicsSection }
        </div>
      </div>
      

    </section>
  );
};


function InvitationMask(props) {

  const { onClickFunction } = props;

  const style = {
    width: '100%',
    height: '100vh',
    position: 'fixed', 
    zIndex: '1000'
  };

  return (
    <div style={ style } onClick={ onClickFunction }></div>
  )
}


const mapStateToProps = (state) => {
  return {
    themes:                      state.themes,
    actualTheme:                 state.actualTheme,
    isWelcomeVideoShown:         state.isWelcomeVideoShown,
    isInvitationToSettingsShown: state.isInvitationToSettingsShown,
  };
};

// const mapDispatch = (dispatch) => ({

// });

export default connect(mapStateToProps)(DashboardPage);