import React from 'react';
import Btn from '../Btn';
import defaultPhotoImg from './images/settings-photo.png';
import { FormPersonal, FormPassword, FormEmail } from '../SettingsForm';
import './SettingsProfile.css';


const SettingsProfile = () => {
  return (
    <div className='settings-profile'>
      <ProfileSection />       {/* Фото и личные данные */}
      <PasswordSection />      {/* Пароль */}
      <EmailSection />         {/* Почта */}
    </div>
  );
};


function ProfileSection() {
  return (
    <div className='settings-profile__section'>

      <div className="settings-profile__title-wrapper">
        <h2 className="settings-profile__title-text">Profil</h2>
        <div className="settings-profile__title-line"></div>
      </div>

      <div className="settings-profile__photo-wrapper">
        <img className="settings-profile__photo-img" src={ defaultPhotoImg } alt="" />
        <Btn addClass={ 'settings-profile__photo-btn' } text='Foto hochladen' />
      </div>

      <FormPersonal />
    </div>
  )
}


function PasswordSection() {
  return (
    <div className='settings-profile__section'>
      <div className="settings-profile__title-wrapper">
        <h2 className="settings-profile__title-text">Passwort</h2>
        <div className="settings-profile__title-line"></div>
      </div>
      <FormPassword />
    </div>
  );
}


function EmailSection() {
  return (
    <div className='settings-profile__section'>
      <div className="settings-profile__title-wrapper">
        <h2 className="settings-profile__title-text">E-Mail</h2>
        <div className="settings-profile__title-line"></div>
      </div>
      <FormEmail />
    </div>
  );
}

export default SettingsProfile;
