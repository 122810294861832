import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import leftArrow from './images/left-arrow.svg';
import rightArrow from './images/right-arrow.svg';
import 'swiper/css';
import 'swiper/css/navigation';
import './TopicSlider.css';


const TopicSlider = (props) => {
  let { cards } = props;
  if (!cards) return;

  const slides = cards.map( (card, i) => <SwiperSlide key={i}>{ card }</SwiperSlide> );


  return (
    <Swiper
      className='swiper-topic'
      modules={[Navigation]}
      spaceBetween={25}
      slidesPerView={'auto'}
      navigation={{
        nextEl: '.swiper-topic__arrow-right',
        prevEl: '.swiper-topic__arrow-left',
      }}
      // onSwiper={(swiper) => {} }
      // onSlideChange={() => {} }
    >
      <img className='swiper-topic__arrow-left' src={ leftArrow } alt='' />
      <img className='swiper-topic__arrow-right' src={ rightArrow } alt='' />
      { slides }
    </Swiper>
  );
};


export default TopicSlider;


// document.addEventListener('DOMContentLoaded', () => {
//   const swiper = new Swiper('.swiper-topic', {
//     navigation: {
//       nextEl: '.swiper-topic__arrow-left',
//       prevEl: '.swiper-topic__arrow-right',
//     },
//   });
// })