import React from 'react';
import { connect } from 'react-redux';
import './InteresSelectedPreview.css';


const InteresSelectedPreview = (props) => {
  const { selectedThemes } = props;

  const previewItems = selectedThemes.map( ( themeProps ) => {
    return <PreviewItem {...themeProps} key={themeProps.themeName}/>
  } )

  return (
    <div className='interes-selected-preview'>
      <div className='interes-selected-preview__title'>Interessantes für Dich</div>

      <div className='interes-selected-preview__items-wrapper'>
        { previewItems }
      </div>

    </div>
  )
};


function PreviewItem(props) {
  const { themeName = 'Legal', mediaCount, themeSubtitle } = props;

  // stars - это кол-во видео по теме
  const stars = [];
  for (let i = 0; i < mediaCount; i += 1) {
    stars.push(<ItemCardStar key={i}/>);
  } 

  return (
    <div className='interes-selected-preview__item'>

      <div className='interes-selected-preview__item-title-block'>
        <h3>{ themeName }</h3>
        <span>{ themeSubtitle }</span>
      </div>

      <div className='interes-selected-preview__item-cards-count'>
        { stars }
      </div>

    </div>
  );
}


function ItemCardStar() {
  return (
    <div className='interes-selected-preview__item-card-star'></div>
  );
}


const mapStateToProps = (state) => {
  const selectedThemes = state.themes
    .filter((theme) => theme.isActive)

  return { selectedThemes }
}

export default connect(mapStateToProps)(InteresSelectedPreview);