
export default class PlayerService {
  // получить элементы и значения
  getPlayerFromTarget =       (ev) => ev.target.closest('.player');
  getVideoElementFromTarget = (ev) => ev.target.closest('.player').querySelector(('.player__video'));
  
  getPlayerElement = () => document.querySelector('.player');
  getVideoElement =  () => document.querySelector('.player__video');
  
  getProgressBar =   () => document.querySelector('.player__controls-progress input');
  getCurrentTimeEl = () => document.querySelector('.player__controls-progress-current-time');
  getCurrentTime =   () => {
    const currentTime = this.getVideoElement()?.currentTime ?? 0;
    return Math.floor(currentTime);
  }

  getDuration = () => {
    const video = this.getVideoElement();
    const value = video ? video.duration : 0;
    return String(
      Math.floor(value)
    );
  }

  // отрисовка времени
  _renderTime = (secondsToRender) => {
    if (!secondsToRender) return '00:00';
    let minutes = Math.floor(secondsToRender / 60);
    if (minutes < 10) minutes = '0' + minutes;
    let seconds = Math.floor(secondsToRender % 60);
    if (seconds < 10) seconds = '0' + seconds;
    return `${minutes}:${seconds}`;
  }

  renderDuration = () => {
    const duration = this.getDuration();
    if (!duration) return '00:00';
    return this._renderTime(duration);
  }

  renderCurrentTime = (currentTime = this.getCurrentTime()) => {
    if (!currentTime) return '00:00';
    return this._renderTime(currentTime);
  }

  isVideoPaused = (video = this.getVideoElement()) => {
    return video.paused || video.ended;
  };



  // новые
  getTimeFormatted = (allSeconds) => {
    if (!allSeconds) return '00:00';
    let minutes = Math.floor((allSeconds) / 60);
    let seconds = Math.floor((allSeconds) % 60);
    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds < 10) seconds = `0${seconds}`;
    return `${minutes}:${seconds}`;
  };

}
