import React from 'react';

// изображения для кнопок
import playIcon from './images/play-control.svg';
import pauseIcon from './images/pause-control.svg';
import nextIcon from './images/next-control.svg';
import volumeIcon from './images/volume-control.svg';
import settingsIcon from './images/settings-control.svg';


const ControlsPanel = (props) => {
  const {
    isVideoPaused,
    time,
    duration,
    playerService,
    onPlayClick,
    onProgressChange,
  } = props;
  
  const playBtnImg = <img src={ isVideoPaused ?  playIcon : pauseIcon }/>

  
  return (
    <div className='player__controls-panel'>
          
      <div 
        className='player__controls-play player__controls-item'
        onClick={ onPlayClick }
      >
        {playBtnImg}
      </div>
 

      <div className='player__controls-progress'>
      
        <span className='player__controls-progress-current-time'> {/* текущее время просмотра */}
          { playerService.getTimeFormatted(time) }
        </span>
        
        <input
          type='range'
          className='progress player__controls-item'
          value={ time }      
          min={ 0 }
          max={ duration } 
          onChange={ onProgressChange }
        />

        <span className='player__controls-progress-duration'> {/* длительность всего видео */}
          { playerService.getTimeFormatted(duration) }
        </span>

      </div>


      <div className='player__controls-next player__controls-item'>
        <img src={ nextIcon } />
      </div>

      <div className='player__controls-volume player__controls-item'>
        <img src={ volumeIcon } />
      </div>

      <div className='player__controls-settings player__controls-item'>
        <img src={ settingsIcon } />
      </div>

    </div>
  );
};

export default ControlsPanel;