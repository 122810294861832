import React from 'react';
import { connect } from 'react-redux';
import Btn from '../Btn/Btn';
import FormService from '../../services/formService';
import './AuthForm.css';

const formService = new FormService();

const AuthForm = (props) => {
  const { 
    name = 'Tim',
    password,
    rabbit,
    setNewPassword,
    setLoggin,
    isFirstAuthDone,
    setFirstAuthDone
  } = props;


  const onFirstTimeAuthBtnClick = ({ target }) => {
    const inputs = target.form.querySelectorAll('input');
    if (!inputs[0].value) return;

    if (inputs[0].value !== inputs[1].value) {
      inputs.forEach((input) => formService.onErrorInput(input));
      console.log("First auth: values don't match");
      return;
    }
    
    if (inputs[0].value !== rabbit) {
      inputs.forEach((input) => formService.onErrorInput(input));
      console.log("Incorrect pass");
      return;
    }

    setNewPassword(inputs[0].value);
    setLoggin(true);
    setFirstAuthDone(true);
  }


  // первый вход, пароль ещё не задавался
  const firstTimeAuthForm = (
    <form className='auth-form'>
      <h2 className="auth-form__title">Willkommen in deiner<br/> Corporate Masterclass, { name }!</h2>

      <div className="auth-form__subtitle-gray">
        Hier findest du alle wichtigen Informationen über unsere Firma<br/> 
        – präsentiert von deinen neuen Kolleg:innen.<br/> 
        Wir wünschen gute Unterhaltung!
      </div>

      <div className="auth-form__subtitle">Bitte vergib zuerst dein Passwort.</div>
      
      <input className="auth-form__input" type="password" name="password" placeholder="Passwort" />
      <input className="auth-form__input" type="password" name="password" placeholder="Passwort wiederholen" />

      <Btn text='Speichern' onClickFunction={ onFirstTimeAuthBtnClick }/> 
    </form>
  );


  const onUsualAuthBtn = ({ target }) => {
    const input = target.form.querySelector('input');
    if (!input.value) {
      console.log('Empty');
      // добавить класс с ошибкой
      return;
    }
    if (input.value !== password) {
      console.log('Password wrong');
      // добавить класс с ошибкой
      return;
    }

    setLoggin(true);
  };

  const usualAuthForm = (
    <form className='auth-form'>
      <h2 className="auth-form__title">Willkommen in deiner<br/> Corporate Masterclass, { name }!</h2>

      <div className="auth-form__subtitle-gray">
        Hier findest du alle wichtigen Informationen über unsere Firma<br/> 
        – präsentiert von deinen neuen Kolleg:innen.<br/> 
        Wir wünschen gute Unterhaltung!
      </div>

      <div className="auth-form__subtitle">Bitte vergib zuerst dein Passwort.</div>
      
      <input 
        className="auth-form__input"
        type="password"
        name="password"
        placeholder="Passwort"
      />

      <Btn text='Speichern' onClickFunction={ onUsualAuthBtn }/> 
    </form>
  );

  return ( isFirstAuthDone ? usualAuthForm : firstTimeAuthForm );
};

const mapStateToProps = (state) => ({
  isFirstAuthDone: state.isFirstAuthDone, 
  isLogged:        state.isLogged, 
  password:        state.settings.password,
  rabbit:          state.rabbit
});

const mapDispatchToProps = (dispatch) => {
  return {
    setNewPassword:   (payload) => dispatch({ type: 'set_new_password', payload }),
    setLoggin:        (payload) => dispatch({ type: 'set_is_logged', payload }),
    setFirstAuthDone:        () => dispatch({ type: 'set_first_auth_done' }),
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);