
export default class LocalStorageService {
  // setLocalState = (newState) => localStorage.setItem('localState', JSON.stringify(newState));
  saveStateLocally = (newState) => {
    localStorage.setItem('localState', JSON.stringify(newState))
  }

  getLocalState = () => {
    const storage = localStorage.getItem('localState');
    if (!storage) return null;
    const settings = JSON.parse(localStorage.getItem('localState'));
    if (!settings.activeCard) return null;
    return settings;
  }
}