import React from 'react';
import SidePanel from '../../components/SidePanel/SidePanel';

import './SettingsPage.css';


const SettingsPage = (props) => {
  const { children } = props;

  return (
    <div className='settings'>
      <SidePanel />
      <div className='settings__content'>
        { children }
      </div>
    </div>
  );
};

export default SettingsPage; 