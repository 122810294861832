import React from 'react';

export default class ErrorBoundary extends React.Component {
  children = this.props.children;

  state = {
    hasError:  false
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <div></div>;
    }

    return ( this.children );
  }
};