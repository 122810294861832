import cardsData from './cardsData';
import themes from './themesData';
import ThemesService from './themesService';
import LocalStorageService from './localStorageService';
import initialPersonSettings from './initialPersonSettings';
import CardsService from './cardsService';


const cardsService        = new CardsService();
const themesService       = new ThemesService();
const localStorageService = new LocalStorageService();


// если настройки не записаны в localStorage, 
// то эта функция их сформирует 
const getDefaultState = () => {
  return {
    activeCard:  'culture1',
    settings:    initialPersonSettings,
    isLogged:    false,
    cardsData,
    themes,
    actualTheme: 'culture',
    rabbit:      'dcm1234q',

    // quiz
    isQuizVisible: false,

    // сценарий
    isFirstAuthDone:             false,
    isWelcomeVideoShown:         false,
    isInvitationToSettingsShown: false,
  };
};


const initialState = localStorageService.getLocalState() ?? getDefaultState();


const reducer = (state = initialState, action ) => {
  let newState;
  switch (action.type) {

    // login, auth, scrips
    case 'set_is_logged':
      newState = { ...state, isLogged: action.payload };
      break;
    
    case 'set_first_auth_done':
      newState = {...state, isFirstAuthDone: true };
      break;

    case 'set_wellcome_video_shown':
      newState = {...state, isWelcomeVideoShown: true };
      break;
    
    // personal
    case 'set_profile_data':
      newState = { ...state, settings: { ...state.settings, ...action.payload } };
      break;

    case 'set_interest_data': // переименовать на фильтр интересов
      newState = { ...state, themes: themesService.updateThemeActiveStatus(state, action.payload) };
      break;

    case 'set_new_password':
      newState = { ...state, settings: { ...state.settings, password: action.payload } };
      break;
    
    case 'set_invitation_to_settings':
      newState = { ...state, isInvitationToSettingsShown: true };
      break;
      
    // themes
    case 'set_actual_theme':
      newState = themesService.setNewActualTheme(state, action.payload);
      break;

    // cards
    case 'update_card_max_time':
      newState = cardsService.setMaxViewedTime(state, action.payload);
      break;

    case 'update_card_percent':
      newState = cardsService.setPercent(state, action.payload);
      break;

    case 'set_active_card':
      newState = cardsService.setActiveCard(state, action.payload);
      break;
    
    case 'unlock_cards':
      newState = cardsService.unlockCards(state, action.payload);
      break;

    case 'reset_new':
      newState = cardsService.resetNew(state, action.payload);
      break;

    case 'reset_new_from_some_cards':
      newState = cardsService.resetNewFromSomeCards(state, action.payload);
      break;
    
    // quiz
    case ('show_quiz'):
      newState = {...state, isQuizVisible: true };
      break;
    
    case ('hide_quiz'):
      newState = {...state, isQuizVisible: false };
      break;

    // default
    default:
      newState = state;
  }

  localStorageService
    .saveStateLocally(newState);

  // console.log('newState', newState);
  return newState;
};


export default reducer;