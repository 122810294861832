// функции для работы с темами


export default class ThemesService {

  // фильтр активных тем
  updateThemeActiveStatus = (state, payload) => {
    const updatedThemes = state.themes.map((theme) => {
      const newActiveStatus = payload.find((obj) => obj.themeName === theme.themeName).isActive;
      theme.isActive = newActiveStatus;
      return theme;
    });
    return updatedThemes;
  }

  // поднять тему в раздел "актуальная"
  setNewActualTheme = (state, payload) => {
    return {...state, actualTheme: payload };
  }

}
