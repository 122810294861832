import React from 'react';
import screenPlay from './images/play-screen.png';
import { connect } from 'react-redux';

const QuizPreview = (props) => {
  const { 
    activeCard,
    dispatchResetNewFromSomeCards,
    dispatchShowQuiz
  } = props;


  // хардкод для презентации
  const onQuizPreviewClick = () => {
    if (!activeCard.resetNewStatusCards || activeCard.resetNewStatusCards.length === 0) return;
    dispatchResetNewFromSomeCards(activeCard.resetNewStatusCards);
    // dispatchShowQuiz();
  }

  return (
    <div className='quiz-preview' onClick={ onQuizPreviewClick }>
      <img className='quiz-preview__play' src={ screenPlay} />
      {/* <h2 className='quiz-preview__title'>QUIZ TITLE</h2>
      <div className='quiz-preview__text'>This is preview. Click HERE!</div> */}
    </div>
  );
};

const mapState = () => ({});

const mapDispatch = (dispatch) => ({
  dispatchResetNewFromSomeCards: (payload) => dispatch({type: 'reset_new_from_some_cards', payload }),
  dispatchShowQuiz: () => dispatch({ type: 'show_quiz' }),
});


export default connect(mapState, mapDispatch)(QuizPreview);