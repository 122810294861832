// настройки профиля

const initialPersonSettings = {
  password:     '123',
  mail:         'Timo.W@gmail.com',
  geburtsdatum: '22.08.1997',
  unternehmen:  'Visograph',
  abteilung:    'Finance',
  name:         'Tim Weise',
  ort:          'Berlin',
};

export default initialPersonSettings;